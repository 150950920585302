import { z } from 'zod';

export const schema = z
  .object({
    isActive: z.boolean().optional(),
    deliveryOption: z.string().optional(),
    HomeDelivery: z.union([z.string(), z.boolean()]).optional(),
    trustedPharmacy: z.number().optional()
  })
  .refine(
    (data) => {
      // A value must be selected for the 'trustedPharmacy' if the delivery option is 'trustedPharmacy'
      return !(data.deliveryOption === 'trustedPharmacy' && data.trustedPharmacy === undefined);
    },
    {
      message: 'A pharmacy is required',
      path: ['trustedPharmacy'] // Show the error at the trustedPharmacy field
    }
  );

export type FormFields = z.infer<typeof schema>;
