import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import type { z } from 'zod';
import { EditModeContext, withEditModeContextProvider } from '../../EditModeContextProvider';
import EditableSections from '../../EditableSections';
import { schema } from './Medicare.schema';

type FormFields = z.infer<typeof schema>;

type MedicareProps = {
  editable?: boolean;
  defaultValues?: FormFields;
  onSubmit: (data: FormFields) => void;
};

function Medicare({ editable = true, defaultValues, onSubmit }: MedicareProps) {
  const { isEditMode } = useContext(EditModeContext);
  const formContext = useForm<FormFields>({
    values: defaultValues,
    resolver: zodResolver(schema)
  });

  return (
    <EditableSections title="Medicare" editable={editable} formContext={formContext} actions={{ onSubmit }}>
      <FormContainer formContext={formContext}>
        <Grid container columnSpacing={10} rowSpacing={5} marginY={2} data-testid="medicare-form">
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="number" label="Number" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="expiration" label="Expiration" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="cardIrn" label="Card IRN" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
        </Grid>
      </FormContainer>
    </EditableSections>
  );
}

export default withEditModeContextProvider(Medicare);
