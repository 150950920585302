import { requestHeaders } from '@/api';
import settings from '@/data/constants';
import { Logger } from '@/utils/logger';

const logger = new Logger('getPharmacies.ts');

// This endpoint could eventually be moved to TS-rest but for now the type definition can exist here
export type Pharmacy = {
  id: number;
  name: string;
  pharmacy_code: string;
  phone: string | null;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  abn: string;
  business_contact_person: string;
  business_contact_position: string;
  business_contact_phone: string;
  business_contact_email: string;
  pharmacist_name: string;
  pharmacist_email: string;
  pharmacist_phone: string;
  pharmacist_ahpraNumber: string;
  account_name: string;
  account_number: string;
  bsb: string;
  bank_name: string;
  agree: boolean | null;
  authorised_first_name: string;
  authorised_last_name: string;
  exp_date: string | null;
  active: boolean;
  home_delivery: boolean;
  modified_by: string | null;
  hpio_number: string | null;
  identifier_name: string;
  auspost_account_number: string | null;
  pharmacy_secondary_email: string | null;
  dispense_limit_daily: number;
  same_day_delivery_enabled: boolean;
  same_day_delivery_postcodes: string | null;
  minimum_delivery_distance_in_km: number;
  is_umeds: boolean;
  latitude: number | null;
  longitude: number | null;
  logo_url: string | null;
  created_date: string;
  modified_date: string;
  created_by: string | null;
  country_id: number;
};

export const getPharmacies = async () => {
  const getPharmaciesUrl = `${settings.url}/circuit/pharmacies?type=dropdown`;

  try {
    const getPharmaciesResponse = await fetch(getPharmaciesUrl, {
      method: 'GET',
      headers: requestHeaders()
    });

    if (!getPharmaciesResponse.ok) {
      throw new Error(`Error: ${getPharmaciesResponse.status}`);
    }

    const pharmaciesData: Pharmacy[] = await getPharmaciesResponse.json();

    return pharmaciesData;
  } catch (error) {
    logger.error('Error fetching pharmacies:', error);
    throw new Error(`Failed to fetch pharmacies: ${error instanceof Error ? error.message : error}`);
  }
};
