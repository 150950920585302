import { useQuery } from '@tanstack/react-query';

import type { Pharmacy } from '@/api/pharmacy/getPharmacies';
import { getPharmacies } from '@/api/pharmacy/getPharmacies';
import { QueryKey } from '@/data/query';

export const useGetPharmacies = () => {
  const { data, isError, isLoading } = useQuery<Pharmacy[]>({
    queryKey: [QueryKey.GetPharmacies],
    queryFn: () => getPharmacies()
  });

  return {
    data,
    isError,
    isLoading
  };
};
