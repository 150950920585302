import { EditModeContext, withEditModeContextProvider } from '@/components/Patient/EditModeContextProvider';
import EditableSections from '@/components/Patient/EditableSections';
import type { FormFields } from '@/components/Patient/forms/PharmacyPreference/PharmacyPreference.schema';
import { schema } from '@/components/Patient/forms/PharmacyPreference/PharmacyPreference.schema';
import { useGetPharmacies } from '@/hooks/rest/useGetPharmacies';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useContext } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { FormContainer, RadioButtonGroup, SelectElement } from 'react-hook-form-mui';

export type PharmacyPreferenceProps = {
  editable?: boolean;
  defaultValues?: FormFields;
  onSubmit: (data: FormFields) => void;
};

const PharmacyPreference = (props: PharmacyPreferenceProps) => {
  const { editable, defaultValues, onSubmit } = props;
  const { data: listOfPharmacies, isLoading: isLoadingPharmacies, isError: isErrorPharmacies } = useGetPharmacies();
  const { isEditMode } = useContext(EditModeContext);

  const formContext = useForm<FormFields>({
    defaultValues: {
      ...defaultValues,
      deliveryOption: defaultValues?.HomeDelivery ? 'homeDelivery' : 'trustedPharmacy',
      trustedPharmacy: defaultValues?.HomeDelivery === false ? defaultValues.trustedPharmacy : undefined
    },
    resolver: zodResolver(schema)
  });

  const { watch } = formContext;
  const deliveryOption = watch('deliveryOption');
  const showTrustedPharmacySelect =
    deliveryOption === 'trustedPharmacy' ||
    (defaultValues?.HomeDelivery === false && deliveryOption !== 'homeDelivery');

  const formattedListOfPharmacies = listOfPharmacies?.map((pharmacy) => ({
    id: pharmacy.id,
    label: pharmacy.name
  }));

  const handleOnSubmit = (formData: FieldValues) => {
    const payload = {
      HomeDelivery: formData.deliveryOption === 'homeDelivery',
      trustedPharmacy: formData.deliveryOption === 'trustedPharmacy' ? formData.trustedPharmacy : null
    };

    if (onSubmit) {
      onSubmit(payload);
    }
  };

  return (
    <EditableSections
      title="Pharmacy Preference"
      editable={editable}
      formContext={formContext}
      actions={{ onSubmit: handleOnSubmit }}
    >
      <FormContainer formContext={formContext}>
        <Box marginTop={4}>
          <RadioButtonGroup
            name="deliveryOption"
            disabled={!isEditMode}
            options={[
              {
                id: 'homeDelivery',
                label: 'Home Delivery'
              },
              {
                id: 'trustedPharmacy',
                label: 'Trusted Pharmacy'
              }
            ]}
            row
          />
          {showTrustedPharmacySelect && isErrorPharmacies && (
            <Box mt={4}>
              <Alert severity="warning">An error has occurred loading the list of trusted pharmacies</Alert>
            </Box>
          )}
          {showTrustedPharmacySelect && !isErrorPharmacies && (
            <Grid container mt={4}>
              <Grid item xs={6}>
                <SelectElement
                  name="trustedPharmacy"
                  label="Choose a trusted pharmacy"
                  options={formattedListOfPharmacies}
                  disabled={!isEditMode || isLoadingPharmacies}
                  fullWidth
                  data-testid={'trusted-pharmacy-select'}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </FormContainer>
    </EditableSections>
  );
};

export default withEditModeContextProvider(PharmacyPreference);
