import { z } from 'zod';

export const schema = z.object({
  number: z
    .string()
    .min(10, { message: 'Number must be exactly 10 characters long.' })
    .max(10, { message: 'Number must be exactly 10 characters long.' })
    .regex(/^\d+$/, { message: 'Invalid medicare number.' })
    .nullable()
    .optional(),
  expiration: z
    .string()
    .regex(/^(0[1-9]|1[0-2])\/\d{4}$/, { message: 'Expiry date must be in MM/YYYY format.' })
    .nullable()
    .optional(),
  cardIrn: z.string().regex(/^\d+$/, { message: 'Invalid card IRN.' }).nullable().optional()
});
